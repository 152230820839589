@use "sass:math";
@use "sass:map";
@use "variables";
@use "sass:color";

@mixin font($size, $weight: null, $family: null) {
  font-size: calc($size / variables.$font-size-default * 1rem);
  @if $weight != null {font-weight: $weight}
  @if $family != null {font-family: $family}
  @if $family == 'go3v2' {letter-spacing: 2px}
}

@mixin autofill($background, $color) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $background inset !important;
    -webkit-text-fill-color: $color !important;
  }
}

@mixin stroke ($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

@mixin ngError() {
  &.ng-invalid.ng-touched {
    @content;
  }
}

@mixin ngSuccess() {
  &.ng-valid.ng-dirty {
    @content;
  }
}

@mixin scrollbar($scrollbar: variables.$color-background, $scrollbarThumb: variables.$color-background) {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background-color: color.scale($scrollbar, $lightness: 5%);
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: color.scale($scrollbarThumb, $lightness: 2%);
  }
}

@mixin hideScrollBar() {
  overflow-y: scroll;
  -ms-overflow-style: none;  // IE 10+
  overflow: -moz-scrollbars-none;  // Firefox old
  scrollbar-width: none; /* Firefox new */
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin retina {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin media-between($lower, $upper) {
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin media-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin media-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-height-between($lower, $upper) {
  @media (min-height: $lower) and (max-height: ($upper - 1)) {
    @content;
  }
}

@mixin media-height-below($breakpoint) {
  @media (max-height: ($breakpoint - 1)) {
    @content;
  }
}

@mixin media-height-above($breakpoint) {
  @media (min-height: $breakpoint) {
    @content;
  }
}

@mixin media-below-and-landscape($breakpoint) {
  @media (max-width: ($breakpoint - 1)) and (min-aspect-ratio: 13/9) {
    @content;
  }
}

@mixin flex($direction: row, $justify: flex-start, $align-items: flex-start, $wrap: wrap) {
  display: flex;
  flex-wrap: $wrap;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align-items;
}

@mixin content-table() {
  table {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border: 0 !important;

    th {
      border: 0;
      border-bottom: 1px solid variables.$color-background-light;
      padding: 10px 0;
    }

    tbody {
      td {
        padding: 10px 0;
        font-size: 12px;
        width: 50%;
      }

      tr:nth-child(even) {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    td {
      border: 0;
    }
  }
}

@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

@mixin default-mode($context-selector: null) {
  :host-context(html.default) {
    @content;
  }
}

@mixin country-mode-ar($context-selector: null) {
  :host-context(html.ar) {
    @content;
  }
}

/*
  Returns a CSS variable property specific to themeing.
  Accepts an optional fallback, and optional map.
  - SCSS: color: mixins.theme-var($theme-primary, blue)
  - CSS:  color: var(--theme-primary, blue)
  Throws error if the variable not part of the map
*/
@function theme-var($key, $fallback: null, $map: variables.$theme-map-default) {
  @if not map.has-key($map, $key) {
    @error "key: '#{$key}', is not a key in map: #{$map}";
  }
  @if ($fallback) {
    @return var($key, $fallback);
  } @else {
    @return var($key);
  }
}

@mixin langArabic() {
  &:lang(ar) {
    @content;
  }
}

@mixin ltrDirection(){
  @include langArabic {
    direction: ltr;
  }
}

@mixin rtlDirection(){
  @include langArabic {
    direction: rtl;
  }
}

@mixin textFill() {
  background: theme-var(variables.$color-focus-var);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  position: relative;
  z-index: 0;
  transition: .3s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    background-origin: border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
}
