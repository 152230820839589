@use "sass:color";
// colors
$color-primary: #ff4669;
$color-primary-action: color.scale($color-primary, $lightness: 10%);
$color-blog-bg: #c4c4c4;

$color-secondary: #ffffff;
$color-secondary-action: #ff4669;

$color-focus: #d3ff07;

$color-background: #000;
$color-background-light: #242424;
$color-background-payment-logo: #383737;
$color-background-input: #edf1f5;
$color-notification-read: #3F3F3F;

$color-light: color.scale(#fff, $lightness: -30%);
$color-dark: #200037;

$color-text: #fff;
$color-text-dark: #000;
$color-text-light: #fff;
$color-text-dark-promo: #8C8C8C;

$color-error: #F20000;
$color-success: #4BB543;
$color-active: #07FF20;
$color-expired: #777777;
$color-availabe: #D3FF07;
$color-profile-error: #F91414;
$color-profile-apply: #00BB83;

$color-rtl-text: #FF9628;

// fonts
$font-default: 'Poppins';

// font size
$font-size-default: 16px;

// transitions
$transition-time-default: 0.2s;

// breakpoints
$bp-xs-ios: 325px;
$bp-iphone-6-8: 376px;
$bp-xs: 536px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-ld: 1200px;
$bp-xl: 1500px;

// vars
$color-primary-var: --color-primary;
$color-primary-action-var: --color-primary-action;
$color-secondary-var: --color-secondary;
$color-secondary-action-var: --color-secondary-action;
$color-focus-var: --color-focus;
$color-background-var: --color-background;
$color-background-light-var: --color-background-light;
$color-background-payment-logo-var: --color-background-payment-logo;
$color-background-input-var: --color-background-input;
$color-notification-read-var: --color-notification-read;
$color-light-var: --color-light;
$color-dark-var: --color-dark;
$color-text-var: --color-text;
$color-text-dark-var: --color-text-dark;
$color-text-light-var: --color-text-light;
$color-error-var: --color-error;
$color-success-var: --color-success;
$color-active-var: --color-active;
$color-expired-var: --color-expired;
$color-availabe-var: --color-availabe;
$color-gradient-text-var: --color-gradient-text;
$color-blog-bg-var: --color-blog-bg;

$theme-map-default: (
  $color-primary-var: $color-primary,
  $color-primary-action-var: $color-primary-action,
  $color-secondary-var: $color-secondary,
  $color-secondary-action-var: $color-secondary-action,
  $color-focus-var: $color-focus,
  $color-background-var: $color-background,
  $color-background-light-var: $color-background-light,
  $color-background-payment-logo-var: $color-background-payment-logo,
  $color-background-input-var: $color-background-input,
  $color-notification-read-var: $color-notification-read,
  $color-light-var: $color-light,
  $color-dark-var: $color-dark,
  $color-text-var: $color-text,
  $color-text-dark-var: $color-text-dark,
  $color-text-light-var: $color-text-light,
  $color-error-var: $color-error,
  $color-success-var: $color-success,
  $color-active-var: $color-active,
  $color-expired-var: $color-expired,
  $color-availabe-var: $color-availabe,
  $color-gradient-text-var: $color-focus,
  $color-blog-bg-var: $color-blog-bg,
);

$theme-map-ar: (
  $color-primary-var: $color-primary,
  $color-primary-action-var: $color-primary-action,
  $color-secondary-var: $color-secondary,
  $color-secondary-action-var: $color-secondary-action,
  $color-focus-var: linear-gradient(101.85deg, #FFCE74 0.93%, #FF9628 71.75%, #E85A00 100%),
  $color-background-var: $color-background,
  $color-background-light-var: $color-background-light,
  $color-background-payment-logo-var: $color-background-payment-logo,
  $color-background-input-var: $color-background-input,
  $color-notification-read-var: $color-notification-read,
  $color-light-var: $color-light,
  $color-dark-var: $color-dark,
  $color-text-var: $color-text,
  $color-text-dark-var: $color-text-dark,
  $color-text-light-var: $color-text-light,
  $color-error-var: $color-error,
  $color-success-var: $color-success,
  $color-active-var: $color-active,
  $color-expired-var: $color-expired,
  $color-availabe-var: $color-availabe,
  $color-gradient-text-var: #FF9628,
  $color-blog-bg-var: #360405,
);
