//@font-face {
//  font-family: "icons";
//  font-display: swap;
//  src: url('/assets/fonts/icons.eot');
//  src: url('/assets/fonts/icons.eot?#iefix') format('eot'),
//  url('/assets/fonts/icons.woff') format('woff'),
//  url('/assets/fonts/icons.ttf') format('truetype')
//}

@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == android {
  $char: "\E001";
}
  @if $filename == apple {
  $char: "\E002";
}
  @if $filename == apply {
  $char: "\E003";
}
  @if $filename == arrow-big {
  $char: "\E004";
}
  @if $filename == arrow-thin {
  $char: "\E005";
}
  @if $filename == arrow-up {
  $char: "\E006";
}
  @if $filename == arrow {
  $char: "\E007";
}
  @if $filename == attention {
  $char: "\E008";
}
  @if $filename == blackjack {
  $char: "\E009";
}
  @if $filename == calendar {
  $char: "\E00A";
}
  @if $filename == cashier {
  $char: "\E00B";
}
  @if $filename == chat {
  $char: "\E00C";
}
  @if $filename == check-checkbox {
  $char: "\E00D";
}
  @if $filename == check-taken {
  $char: "\E00E";
}
  @if $filename == check {
  $char: "\E00F";
}
  @if $filename == close {
  $char: "\E010";
}
  @if $filename == compress {
  $char: "\E011";
}
  @if $filename == copy {
  $char: "\E012";
}
  @if $filename == delete {
  $char: "\E013";
}
  @if $filename == deposit {
  $char: "\E014";
}
  @if $filename == edit-file {
  $char: "\E015";
}
  @if $filename == edit {
  $char: "\E016";
}
  @if $filename == error {
  $char: "\E017";
}
  @if $filename == expand-all {
  $char: "\E018";
}
  @if $filename == expand {
  $char: "\E019";
}
  @if $filename == facebook {
  $char: "\E01A";
}
  @if $filename == fast-cashout {
  $char: "\E01B";
}
  @if $filename == favorite {
  $char: "\E01C";
}
  @if $filename == filter {
  $char: "\E01D";
}
  @if $filename == fullscreen {
  $char: "\E01E";
}
  @if $filename == games {
  $char: "\E01F";
}
  @if $filename == gift-new {
  $char: "\E020";
}
  @if $filename == google {
  $char: "\E021";
}
  @if $filename == heart {
  $char: "\E022";
}
  @if $filename == help {
  $char: "\E023";
}
  @if $filename == home {
  $char: "\E024";
}
  @if $filename == info-new {
  $char: "\E025";
}
  @if $filename == info {
  $char: "\E026";
}
  @if $filename == ios {
  $char: "\E027";
}
  @if $filename == last-player {
  $char: "\E028";
}
  @if $filename == left {
  $char: "\E029";
}
  @if $filename == like {
  $char: "\E02A";
}
  @if $filename == like_filled {
  $char: "\E02B";
}
  @if $filename == livecasino {
  $char: "\E02C";
}
  @if $filename == lock {
  $char: "\E02D";
}
  @if $filename == locked {
  $char: "\E02E";
}
  @if $filename == logout {
  $char: "\E02F";
}
  @if $filename == loyalty-program {
  $char: "\E030";
}
  @if $filename == mail {
  $char: "\E031";
}
  @if $filename == menu {
  $char: "\E032";
}
  @if $filename == minus {
  $char: "\E033";
}
  @if $filename == new {
  $char: "\E034";
}
  @if $filename == notifications {
  $char: "\E035";
}
  @if $filename == notify {
  $char: "\E036";
}
  @if $filename == offers {
  $char: "\E037";
}
  @if $filename == password {
  $char: "\E038";
}
  @if $filename == pdf {
  $char: "\E039";
}
  @if $filename == picture-in-picture {
  $char: "\E03A";
}
  @if $filename == play {
  $char: "\E03B";
}
  @if $filename == plus {
  $char: "\E03C";
}
  @if $filename == pointer-down {
  $char: "\E03D";
}
  @if $filename == prize {
  $char: "\E03E";
}
  @if $filename == question {
  $char: "\E03F";
}
  @if $filename == rocket {
  $char: "\E040";
}
  @if $filename == roulette {
  $char: "\E041";
}
  @if $filename == search {
  $char: "\E042";
}
  @if $filename == settings {
  $char: "\E043";
}
  @if $filename == slots {
  $char: "\E044";
}
  @if $filename == star {
  $char: "\E045";
}
  @if $filename == tablegames {
  $char: "\E046";
}
  @if $filename == telegram {
  $char: "\E047";
}
  @if $filename == text {
  $char: "\E048";
}
  @if $filename == time {
  $char: "\E049";
}
  @if $filename == trash {
  $char: "\E04A";
}
  @if $filename == twitter {
  $char: "\E04B";
}
  @if $filename == user {
  $char: "\E04C";
}
  @if $filename == viber {
  $char: "\E04D";
}
  @if $filename == video {
  $char: "\E04E";
}
  @if $filename == windows {
  $char: "\E04F";
}
  @if $filename == x-twitter {
  $char: "\E050";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-android {
  @include icon(android);
}
.icon-apple {
  @include icon(apple);
}
.icon-apply {
  @include icon(apply);
}
.icon-arrow-big {
  @include icon(arrow-big);
}
.icon-arrow-thin {
  @include icon(arrow-thin);
}
.icon-arrow-up {
  @include icon(arrow-up);
}
.icon-arrow {
  @include icon(arrow);
}
.icon-attention {
  @include icon(attention);
}
.icon-blackjack {
  @include icon(blackjack);
}
.icon-calendar {
  @include icon(calendar);
}
.icon-cashier {
  @include icon(cashier);
}
.icon-chat {
  @include icon(chat);
}
.icon-check-checkbox {
  @include icon(check-checkbox);
}
.icon-check-taken {
  @include icon(check-taken);
}
.icon-check {
  @include icon(check);
}
.icon-close {
  @include icon(close);
}
.icon-compress {
  @include icon(compress);
}
.icon-copy {
  @include icon(copy);
}
.icon-delete {
  @include icon(delete);
}
.icon-deposit {
  @include icon(deposit);
}
.icon-edit-file {
  @include icon(edit-file);
}
.icon-edit {
  @include icon(edit);
}
.icon-error {
  @include icon(error);
}
.icon-expand-all {
  @include icon(expand-all);
}
.icon-expand {
  @include icon(expand);
}
.icon-facebook {
  @include icon(facebook);
}
.icon-fast-cashout {
  @include icon(fast-cashout);
}
.icon-favorite {
  @include icon(favorite);
}
.icon-filter {
  @include icon(filter);
}
.icon-fullscreen {
  @include icon(fullscreen);
}
.icon-games {
  @include icon(games);
}
.icon-gift-new {
  @include icon(gift-new);
}
.icon-google {
  @include icon(google);
}
.icon-heart {
  @include icon(heart);
}
.icon-help {
  @include icon(help);
}
.icon-home {
  @include icon(home);
}
.icon-info-new {
  @include icon(info-new);
}
.icon-info {
  @include icon(info);
}
.icon-ios {
  @include icon(ios);
}
.icon-last-player {
  @include icon(last-player);
}
.icon-left {
  @include icon(left);
}
.icon-like {
  @include icon(like);
}
.icon-like_filled {
  @include icon(like_filled);
}
.icon-livecasino {
  @include icon(livecasino);
}
.icon-lock {
  @include icon(lock);
}
.icon-locked {
  @include icon(locked);
}
.icon-logout {
  @include icon(logout);
}
.icon-loyalty-program {
  @include icon(loyalty-program);
}
.icon-mail {
  @include icon(mail);
}
.icon-menu {
  @include icon(menu);
}
.icon-minus {
  @include icon(minus);
}
.icon-new {
  @include icon(new);
}
.icon-notifications {
  @include icon(notifications);
}
.icon-notify {
  @include icon(notify);
}
.icon-offers {
  @include icon(offers);
}
.icon-password {
  @include icon(password);
}
.icon-pdf {
  @include icon(pdf);
}
.icon-picture-in-picture {
  @include icon(picture-in-picture);
}
.icon-play {
  @include icon(play);
}
.icon-plus {
  @include icon(plus);
}
.icon-pointer-down {
  @include icon(pointer-down);
}
.icon-prize {
  @include icon(prize);
}
.icon-question {
  @include icon(question);
}
.icon-rocket {
  @include icon(rocket);
}
.icon-roulette {
  @include icon(roulette);
}
.icon-search {
  @include icon(search);
}
.icon-settings {
  @include icon(settings);
}
.icon-slots {
  @include icon(slots);
}
.icon-star {
  @include icon(star);
}
.icon-tablegames {
  @include icon(tablegames);
}
.icon-telegram {
  @include icon(telegram);
}
.icon-text {
  @include icon(text);
}
.icon-time {
  @include icon(time);
}
.icon-trash {
  @include icon(trash);
}
.icon-twitter {
  @include icon(twitter);
}
.icon-user {
  @include icon(user);
}
.icon-viber {
  @include icon(viber);
}
.icon-video {
  @include icon(video);
}
.icon-windows {
  @include icon(windows);
}
.icon-x-twitter {
  @include icon(x-twitter);
}
